import Editor from "../../Components/common/Editor";
import trans from "./../../../Languages/Languages";
import {useState} from "react";
import {Button, Card, message} from "antd";
import React, {useEffect} from "react";
import API from "../../../API";
import { useLocation } from "react-router";

const TuitionFees = () => {
  message.config({
    top: 80,
  });
  // eslint-disable-next-line
  const [tuitionFeeEditorHtml, setTuitionFeeEditorHtml] = useState("");
  const [currentRateEditorHtml, setCurrentRateEditorHtml] = useState("");
  const [scholarShipEditorHtml, setScholarShipEditorHtml] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [feeDegreeData, setFeeDegreeData] = useState();

  const handleTuitionFeeChange = (html) => {
    setTuitionFeeEditorHtml(html);
  };
  const handleRateChange = (html) => {
    setCurrentRateEditorHtml(html);
  };
  const handleScholarChange = (html) => {
    setScholarShipEditorHtml(html);
  };

  const currentDegreeID = window.location.hash.split("/")[3];

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///                  Use Effect
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    const init = () => {
      API.get(`/admin/get/degree/fee/${currentDegreeID}`)
        .then((res) => {
          setFeeDegreeData(res.data.scholarship_entitlement);
          setTuitionFeeEditorHtml(res.data.tution_fee);
          setCurrentRateEditorHtml(res.data.current_rates);
          setScholarShipEditorHtml(res.data.scholarship_entitlement);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    init();
  }, [currentDegreeID, location]);

  const handleSaveSection = async () => {
    // setLoading(true);
    const formData = new FormData();
    formData.append("onlinedegree_id", currentDegreeID);
    formData.append("tution_fee", tuitionFeeEditorHtml);
    formData.append("current_rates", currentRateEditorHtml);
    formData.append("scholarship_entitlement", scholarShipEditorHtml);

    // await API.post(`/admin/degree/fee/update/${currentDegreeID}​`, formData)
    await API.post(`/admin/degree/fee/update/${currentDegreeID}`, formData)
      .then((res) => {
        message.success("Saved Successfully", 3);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Something went wrong, please try again", 3);
        setLoading(false);
      });
  };
  return (
    <Card loading={loading} bordered={false} style={{ minHeight: 600 }}>
      <div className="pages-container">
        <div className="content-wrapper">
          {/*  text */}

          {/* Tuition Fees */}
          <div className="bg-content-container mb-0">
            <div className="fw-600 fw-15 mb-2">{trans.TuitionFees}</div>
            <Editor
              key="tuition"
              editorHtml={tuitionFeeEditorHtml}
              setEditorHtml={setTuitionFeeEditorHtml}
              handleChange={handleTuitionFeeChange}
            />
          </div>
          {/* Current Rates */}
          <div className="bg-content-container mb-0">
            <div className="fw-600 fw-15 mb-2">{trans.CurrentRates}</div>
            <Editor
              key="rate"
              editorHtml={currentRateEditorHtml}
              setEditorHtml={setCurrentRateEditorHtml}
              handleChange={handleRateChange}
            />
          </div>
          {/* Scholarship Entitlement */}
          <div className="bg-content-container mb-0">
            <div className="fw-600 fw-15 mb-2">
              {trans.ScholarshipEntitlement}
            </div>
            <Editor
              key="scholarship"
              editorHtml={scholarShipEditorHtml}
              setEditorHtml={setScholarShipEditorHtml}
              handleChange={handleScholarChange}
            />
          </div>
        </div>
        {/* save changes */}

        <Button
          onClick={handleSaveSection}
          className="btn-save-changes"
          type="primary"
          htmlType="submit"
        >
          {trans.Save}
        </Button>
      </div>
    </Card>
  );
};

export default TuitionFees;
